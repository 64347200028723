package com.cirnovarenie.cirnovareniesite.js.utils

import dev.inmo.micro_utils.common.*
import kotlinx.browser.document
import kotlinx.dom.appendElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLTextAreaElement
import kotlin.js.json

fun performCopy(target: HTMLElement, notificationConfig: Any?) {
    val content = when {
        target.hasAttribute("copy-target") -> document.querySelector(
            target.getAttribute("copy-target")!!
        )
        else -> target
    } ?.innerHTML ?: return
    val textarea = document.body ?.appendElement(
        "textarea"
    ) {} as HTMLTextAreaElement
    textarea.value = content
    textarea.select()
    document.execCommand("copy")
    textarea.remove()
    notificationConfig ?.let {
        js("UIkit").notification(notificationConfig)
    }
}
