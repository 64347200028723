package com.cirnovarenie.cirnovareniesite.js

import com.cirnovarenie.cirnovareniesite.js.utils.performCopy
import com.cirnovarenie.cirnovareniesite.js.utils.registerPreview
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.*
import kotlin.js.json

/**
 * На маленьких экранах из-за стиля в materialize.css ".card .card-content" пагинация не влезает. Это грязный хак:)
 */
private fun fixPaginationPaddings() {
    val elements = document.querySelectorAll(".pagination")
    (0 until elements.length).forEach {
        elements.item(it) ?.also { paginationElement ->
            paginationElement.parentElement ?.also { cardContentElement ->
                if (cardContentElement.classList.contains("card-content")) {
                    cardContentElement.setAttribute(
                        "style",
                        (cardContentElement.getAttribute("style") ?: "") + "padding: 24px 0;"
                    )
                }
            }
        }
    }
}

fun main() {
    document.addEventListener(
        "DOMContentLoaded",
        {
            window.asDynamic().ktContainer = json(
                "performCopy" to ::performCopy
            )
            if (window.location.href.contains("offers/new")) {
                val fileElements = document.getElementsByTagName("input")
                var fileInputElement: HTMLInputElement? = null
                var galleryInputElement: HTMLElement? = document.getElementById("gallery") as? HTMLElement
                var textInputElement: HTMLInputElement? = null
                var imageElement: HTMLElement? = null
                (0 until fileElements.length).forEach { i ->
                    (fileElements[i] as? HTMLInputElement) ?.also { inputElement ->
                        when {
                            inputElement.type == "file" -> {
                                fileInputElement = inputElement
                                inputElement.getAttribute("preview-tag-id") ?.also { previewTagId ->
                                    (document.getElementById(previewTagId) as? HTMLElement) ?.also { element ->
                                        imageElement = element
                                    }
                                }
                            }
                            inputElement.name == "textArea" -> textInputElement = inputElement
                        }
                    }
                }
                registerPreview(
                    fileInputElement ?: return@addEventListener,
                    galleryInputElement ?: return@addEventListener,
                    textInputElement ?: return@addEventListener,
                    imageElement ?: return@addEventListener
                )
            }
//            M.AutoInit()
//            M.CharacterCounter.init(document.querySelectorAll(".$withCounterClassname"))
//            fixPaginationPaddings()
        }
    )
}
