package com.cirnovarenie.cirnovareniesite.js.utils

import dev.inmo.micro_utils.common.*
import kotlinx.browser.document
import kotlinx.dom.*
import kotlinx.html.*
import kotlinx.html.dom.create
import org.w3c.dom.*
import org.w3c.files.*

fun registerPreview(
    inputElement: HTMLInputElement,
    galleryElement: HTMLElement,
    textInputElement: HTMLInputElement,
    imagesContainer: HTMLElement
) {
    fun updateGalleryVisibility(visible: Boolean) {
        if (visible) {
            galleryElement.removeClass("gone")
        } else {
            galleryElement.addClass("gone")
        }
    }
    inputElement.onchange = {
        imagesContainer.clear()
        inputElement.files ?.also { files ->
            for (i in (0 until files.length)) {
                files[i] ?.also { file ->
                    val reader: FileReader = FileReader()

                    reader.onload = {
                        imagesContainer.appendChild(
                            document.create.img {
                                src = ((it.target.asDynamic()).result as String)
                                classes = setOf(imagesContainer.getAttribute("subclasses") ?: return@img)
                            }
                        )
                    }

                    reader.readAsDataURL(file)
                }
            }
            updateGalleryVisibility(files.length > 1)
        }
    }
    updateGalleryVisibility(false)
}
